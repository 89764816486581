$color-c:#ccc;
$color-e:#eee;
$color-d:#F0F0F0;
$red:#E13C34;
$red-lighter:#ff6674;
$aqua:#36B0F0;
$green:#30B050;
$gray:#95989A;
$copyright:#666666;
$white:#fff;
$black:#262626;
$footer:#1c1c1c;
$yellow:#FFFF0B;
$border:#d2d2d2;
$gray-2:#eaeaea;
$gray-3:#afabab;
$gray-4:#b5b5b5;
$gray-dark:#6e7173;
$gray-btn-background:#595959;
$green-btn-background:green;
$red-btn-background:red;
$yellow-btn-background:yellow;
$thin-gray:#e8e8e8;
$backend-side-bar-title:#d0d0d0;
$thin-black:#2f2f2f;
$box-shadow: #999999;
$sola-bg:#f9f9f9;
$blue:#7189F7;
$input: #313131;
$sola-color:#9abaeb;
$bg-black: rgba(0,0,0,0.75);
$checkS: #008359;
$thin-red: #FED5D6;

$line-height-base: 1.6;
$text-color: #353535;//#636b6f;
$fontType1: "Noto Sans Japanese","Kozuka Gothic Pro", "小塚ゴシック Pro", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;

$top-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.15);
$top-child-box-shadow: 0px 7px 14px 0px rgba(0,0,0,0.1);
$section-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.25);

$card-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15);//0px 0px 14px 0px rgba(0,0,0,0.15);
$card-box-radius: 0.1rem;
$card-bg-gray: #f7f7f7;
$card-border-gray: #eee;

$mokuji-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.15);

$menu-splitter: #f0f0f0;

/* Slide In */
@keyframes slideInMenu {
	0% {
		opacity: 0;
		left: -10%;
	}
	100% {
		opacity: 1;
		left: 0%;
	}
}
