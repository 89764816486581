@import "variables";
@import "side";
#header-menu {
    height: 50px;
    ul {
        li {
            text-align: center;
			border-right: 1px solid $menu-splitter;
    		> a {
                cursor: pointer;
    		    text-shadow: none;
    		    color: $black;
    		    font-size: 14px;
                padding: 0;
                // margin: 0 15px;
                &:hover {
                    // background: $white;
                }
    		}
            ul.dropdown-menu {
                background: transparent;
            }
    	}
        li.menu-active {
            font-weight: 700;
        }
		li.logo-to-top {
			> a {
				line-height: 0;

			}
		}
        li.parents-menu {
            padding: 0;
            position: relative;
            & > span, a {
				display: block;
				padding: 12px 40px;
				cursor: pointer;
				font-size: 1.2em;
				height: 50px;
				i {
					margin: 0 0.3em;
					color: $gray-3;
				}
            }
			&:hover {
				& > span, & > a {
					background: $red-lighter;
					color: $white;
					i {
						color: #bd1d15;
					}
				}
			}
            ul.child-menu {
                position: absolute;
                min-width: 100%;
                display: none;
                background: $white;
                box-shadow: $top-child-box-shadow;
                li {
                    border-bottom: 1px solid $menu-splitter;
                    text-align: left;
                    a {
						font-size: 1.1em;
                        display: block;
                        padding: 15px 60px 15px 30px;
						white-space: nowrap;

                        i {
                            color: $gray;
                            padding-right: 5px;
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
					&:hover {
						a {
							font-weight: bold;
							text-decoration: none;
						}
					}
                }
            }
        }
    }
    ul.navbar-right {
        margin-right: 0;
        li {
            padding: 14px 0;
			border-right: none;
            a {
                padding: 0 30px;
                color: $gray-4;
				font-size: 0.8em;
                &:hover {
                    background: inherit;
					color: $black;
                }
            }
            &:last-child {
                a {
                    border-left: 1px solid $menu-splitter;
                }
            }
			&:first-child {
				a {
					border-left: none;
				}
			}
        }
    }
}
.line-no {
    line-height: 0;
}
nav.navbar {
    border: none;
}
.navbar {
    border-radius: 0;
    margin-bottom: 0;
}
.navbar img {
    height: 50px;
}
.navbar-nav>li>a {
    padding-top: 0;
    padding-bottom: 0;
}
nav.navbar {
    z-index: 999;
    box-shadow: $top-box-shadow;
    max-height: 50px;
    ul.nav {

    }
}
.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
}
footer {
    margin-top: 20px;
    overflow: hidden;
    padding: 50px 20px;
    background: $footer;
    position: relative;
    text-align: center;
    z-index: 999;
    color: $copyright;
    span {
        text-align: left;
        padding-bottom: 10px;
        padding-right: 20px;
    }
    p {
        color: $white;
    }
    a {
        color: $gray;
        &:hover {
            color: $gray;
        }
    }
    .fa {
        color: $white;
    }
    .copyright {
        position: absolute;
        bottom: 60px;
        right: 20px;
        padding: 0 15px;
    }
}
.sns-btn {
    padding: 0 15px 20px;
    text-align: left;
    a {
        display: inline-block;
        font-size: 25px;
        width: 38px;
        height: 38px;
        color: $white;
        margin: 0 4px;
    }
}
