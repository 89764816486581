.menu-trigger a,
.menu-trigger span {
	display: inline-block;
	transition: all .4s;
	box-sizing: border-box;
}
.menu-trigger a {
	// position: fixed;
	position: relative;
	width: 60px;
	height: 60px;
	padding-right: 10px;
	padding-left: 10px;
	z-index: 9999;
	span {
		position: absolute;
		width: 40px;
		height: 2px;
		background-color: $white;
		border-radius: 1px;
		&:nth-of-type(1) {
			top: 15px;
		}
		&:nth-of-type(2) {
			top: 27px;
		}
		&:nth-of-type(3) {
			top: 39px;
		}
	}
	&:after {
		position: absolute;
		left: 0;
		bottom: -15px;
		content: '';
		display: block;
		width: 100%;
		color: #27477A;
		font-size: 12px;
		text-decoration: none;
		text-align: center;
		transition: all .4s;
	}
}
.menu-trigger {
	a.active{
		&:after {
			content: '';
			bottom: -15px;
		}
		span {
			&:nth-of-type(1) {
				-webkit-transform: translateY(12px) rotate(-45deg);
				transform: translateY(12px) rotate(-45deg);
			}
			&:nth-of-type(2) {
				opacity: 0;
			}
			&:nth-of-type(3) {
				-webkit-transform: translateY(-12px) rotate(45deg);
				transform: translateY(-12px) rotate(45deg);
			}
		}
	}
}
.submenu {
	width: 80%;
	height: 100%;
	position: fixed;
	top: 0;
	right: -80%;
	padding-top: 60px;
	background: rgba(255, 255, 255, 0.9);
	color: $black;
	z-index: 1001;
	opacity: .95;
	transition: ease .4s;
	.submenu_box {
		ul.sp-menu-top {
			li {
				border-bottom: 1px solid $border;
				span {
					padding: 10px;
					font-size: 0.75rem;
					display: block;
					color: $gray-dark;
				}
				a{
					font-size: 1rem;
					padding: 10px;
					display: block;
					color: $black;
					background: $white;
					i {
						width: 1.5em;
					}
				}
				&.sp-menu-header {
					text-align: right;
					span {
						font-size: 0.5rem;
					}
				}
			}
		}
		ul.sp-menu-bottom {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			text-align: right;
			li {
				display: inline-block;
			}
			a {
				color: $gray-dark;
				display: inline-block;
				padding: 0.5rem;
			}
		}
	}
}
.submenu_box {

}
.dummy {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 1000%;
	background: #000;
	opacity: .6;
	z-index: 150;
}
.open {
	right: 0;
}
.sp-bar {
	width: 100%;
	background: $black;
	position:fixed;
	z-index: 1001;
	top: 0;
	left: 0;
}

@media screen and (min-width: 992px) {
	.submenu {
		display: none;
	}
	.dummy {
		display: none;
	}
}
